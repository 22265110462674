import React from "react";
import axios from "axios";
import {
  B2B_PORTAL_JWT_SECRET_KEY,
  B2B_PORTAL_CSRF_TOKEN,
  B2B_PORTAL_AUTH_INVALID_SESSION,
  NETWORK_CALL_FAILED,
  B2B_PORTAL_AUTH_REDIRECT_TO,
} from "redmond";
import {
  useCorpRedirects,
  USER_MEDIUM_KEY,
  USER_SOURCE_KEY,
} from "@capone/common";
import { analyticsEventApi } from "../../api/v1/paths";

import { createRequestLog, createResponseLog } from "../Logger";
import { useUtmParams } from "../../context/userSource";
import { trackEvent } from "../../api/v1/analytics/trackEvent";
import { config } from "../../api/config";

interface IAxiosInterceptors {
  children?: React.ReactNode;
  isAgentPortal: boolean;
}

const defaultProps: Partial<IAxiosInterceptors> = {
  isAgentPortal: false,
};

const AxiosInterceptors = (props: IAxiosInterceptors) => {
  const { children, isAgentPortal } = props;
  const utmParams = useUtmParams();
  useCorpRedirects(axios, config.TENANT);

  const getCookie = (name: string) => {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
    else return undefined;
  };

  const getCsrfToken = () => getCookie(B2B_PORTAL_CSRF_TOKEN);

  React.useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (res) => {
        createResponseLog(res);
        return res;
      },
      (error) => {
        if (
          (getCookie("Hopper-Session") &&
            sessionStorage.getItem("onboarding") === "true") ||
          sessionStorage
            .getItem(B2B_PORTAL_AUTH_REDIRECT_TO)
            ?.includes(`onboarding`)
        ) {
          // user was sent from the admin onboarding page and redirect will be handled on loading page
          return Promise.reject(error);
        }

        if (
          !error?.request?.responseURL.includes("event") &&
          !error?.request?.responseURL.includes("userInfo")
        ) {
          const responseStatus = error.response?.status;

          if (responseStatus === 401 || responseStatus === 403) {
            if (error.response?.data.LoginFailedReason) {
              sessionStorage.setItem(
                B2B_PORTAL_AUTH_INVALID_SESSION,
                JSON.stringify(error.response?.data)
              );
            }
            return Promise.reject(error);
          }
          trackEvent({
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
              endpoint: error?.request?.responseURL,
              failure_code: error?.request?.status,
              failure_reason: error?.request?.statusText,
              failure_message: error?.request?.responseText,
            },
          });
        }

        return Promise.reject(error);
      }
    );

    const requestInterceptor = axios.interceptors.request.use(
      (req) => {
        const csrfToken = getCsrfToken();

        //TODO: remove GCP token
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            B2B_PORTAL_JWT_SECRET_KEY
          )}`,
          ...(csrfToken && { "H-Csrf-Token": csrfToken }),
        };

        req.headers.set({ ...req.headers, ...headers });

        if (req.url === analyticsEventApi) {
          req.data.properties = {
            ...req.data.properties,
            is_agent_session: isAgentPortal,
          };
          if (utmParams) {
            const { userSource, userMedium } = utmParams;
            req.data.properties[USER_SOURCE_KEY] = userSource;
            req.data.properties[USER_MEDIUM_KEY] = userMedium;
          }
        }

        createRequestLog(req);
        return req;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    //runs on component unmount
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  return <>{children}</>;
};

AxiosInterceptors.defaultProps = defaultProps;

export default AxiosInterceptors;
